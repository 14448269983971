import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import DonationListing from '../components/DonationListing';

const DonatePage = () => {
  const {
    datoCmsDonate: {
      seoMetaTags,
      bannerImage,
      bannerText,
      title,
      singleDonations,
      singleDonationsOverline,
      regularDonations,
      regularDonationsOverline,
    },
  } = useStaticQuery(graphql`
    query DonatePageQuery {
      datoCmsDonate {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerImage {
          gatsbyImageData(layout: FULL_WIDTH)
          alt
        }
        bannerText
        singleDonationsOverline
        singleDonations {
          id
          image {
            gatsbyImageData(width: 920, height: 600)
            alt
          }
          amount
          description
        }
        regularDonationsOverline
        regularDonations {
          id
          image {
            gatsbyImageData(width: 920, height: 600)
            alt
          }
          amount
          description
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          heading={title}
          image={bannerImage}
          text={bannerText}
          isDonatePage={true}
        />
        <DonationListing
          overline={singleDonationsOverline}
          heading={'Single Donations'}
          donationItems={singleDonations}
          donationType={true}
          isDonatePage={true}
        />
        <DonationListing
          overline={regularDonationsOverline}
          heading={'Regular Donations'}
          donationItems={regularDonations}
          donationType={false}
          isDonatePage={true}
        />
      </main>
    </Layout>
  );
};

export default DonatePage;
